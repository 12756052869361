import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import Link from 'utils/localized-link';

import GetAppCard from 'components/get-app-card';

import FacebookIcon from 'images/icons/facebook.inline.svg';
import InstagramIcon from 'images/icons/instagram.inline.svg';
import TwitterIcon from 'images/icons/twitter-x.inline.svg';
import LinkedInIcon from 'images/icons/linkedin.inline.svg';

import centro2020Image from 'images/centro-2020.png';
import logoImage from 'images/logo--white.png';
import portugal2020Image from 'images/portugal-2020.png';
import ueFedrImage from 'images/ue-fedr.png';
import eitImage from 'images/eit.png';
import eitEUImage from 'images/eit-eu.png';

import Modal from '../../shared/components/objects/modal';

import './styles.scss';

const Footer = () => {
  const [showModal, setShowModal] = useState(false);

  const handleLogoClick = () => {
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  return (
    <footer className="footer">
      <div className="container">
        <div className="row">
          <div className="col col-12 col-md-6 col-lg-7 col-xl-8">
            <div className="row">

              <div className="col col-12 col-sm-4 col-xl-4">
                <div className="footer__item">
                  <h6 className="footer__item-title">
                    <FormattedMessage id="footer.items.company.title" />
                  </h6>
                  <div className="footer__list">
                    <li className="footer__list-item">
                      <Link
                        className="footer__link"
                        to="/expertise"
                      >
                        <FormattedMessage id="footer.items.company.links.expertise" />
                      </Link>
                    </li>
                    <li className="footer__list-item">
                      <Link
                        className="footer__link"
                        to="/about"
                      >
                        <FormattedMessage id="footer.items.company.links.about_us" />
                      </Link>
                    </li>
                    <li className="footer__list-item">
                      <Link
                        className="footer__link"
                        to="/contacts"
                      >
                        <FormattedMessage id="footer.items.company.links.contacts" />
                      </Link>
                    </li>
                   
                  </div>
                </div>
              </div>
              <div className="col col-12 col-sm-4 col-xl-4">
                <div className="footer__item">
                  <h6 className="footer__item-title">
                    <FormattedMessage id="footer.items.products.title" />
                  </h6>
                  <div className="footer__list">
                    <li className="footer__list-item">
                      <Link
                        className="footer__link"
                        to="/products#robot"
                      >
                        <FormattedMessage id="footer.items.products.links.robot" />
                      </Link>
                    </li>
                    <li className="footer__list-item">
                      <Link
                        className="footer__link"
                        to="/products#mission_control"
                      >
                        <FormattedMessage id="footer.items.products.links.mission_control" />
                      </Link>
                    </li>
                    <li className="footer__list-item">
                      <Link
                        className="footer__link"
                        to="/products#customer"
                      >
                        <FormattedMessage id="footer.items.products.links.customer" />
                      </Link>
                    </li>
                    <li className="footer__list-item">
                      <Link
                        className="footer__link"
                        to="/products#courier"
                      >
                        <FormattedMessage id="footer.items.products.links.courier" />
                      </Link>
                    </li>
                    <li className="footer__list-item">
                      <Link
                        className="footer__link"
                        to="/products#drive"
                      >
                        <FormattedMessage id="footer.items.products.links.drive" />
                      </Link>
                    </li>
                    <li className="footer__list-item">
                      <Link
                        className="footer__link"
                        to="/products#store"
                      >
                        <FormattedMessage id="footer.items.products.links.store" />
                      </Link>
                    </li>
                  </div>
                </div>
              </div>
             
              <div className="col col-12 col-sm-4 col-xl-4">
                <div className="footer__item">
                  <h6 className="footer__item-title">
                    <FormattedMessage id="footer.items.social.title" />
                  </h6>
                  <div className="footer__social-wrapper">
                    <a
                      className="footer__social-link"
                      href="https://www.facebook.com/youshipcom/"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <FacebookIcon />
                    </a>
                    <a
                      className="footer__social-link"
                      href="https://www.instagram.com/youshipcom/"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <InstagramIcon />
                    </a>
                    <a
                      className="footer__social-link"
                      href="https://twitter.com/youshipcom/"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <TwitterIcon />
                    </a>
                    <a
                      className="footer__social-link"
                      href="https://www.linkedin.com/company/youship/"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <LinkedInIcon />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col col-12 col-md-6 col-lg-5 col-xl-4">
            <GetAppCard />
          </div>
        </div>
        <div className="footer__bottom">
          <div className="footer__support order-md-2">
            {/* <img
              alt="Centro 2020"
              className="footer__support-image"
              role="button"
              src={centro2020Image}
              onClick={handleLogoClick}
            />
            <img
              alt="Portugal 2020"
              className="footer__support-image"
              role="button"
              src={portugal2020Image}
              onClick={handleLogoClick}
            />
            <img
              alt="União Europeia Fundo Europeu de Desenvolvimento Regional"
              className="footer__support-image"
              role="button"
              src={ueFedrImage}
              onClick={handleLogoClick}
            /> */}
           {/*  <img
              alt="EIT"
              className="footer__support-image"
              role="button"
              src={eitImage}
            /> */}
          </div>
          <div className="footer__copyright-wrapper order-md-1">
            <img
              alt="Logo"
              className="footer__logo"
              src={logoImage}
            />
            <div className="footer__copyright">
              <FormattedMessage
                id="footer.copyright.message"
                values={{ year: new Date().getFullYear() }}
              />
              &nbsp;·&nbsp;
              <Link
                className="footer__link"
                to="/terms-and-conditions"
              >
                <FormattedMessage id="footer.copyright.links.terms_and_conditions" />
              </Link>
              &nbsp;·&nbsp;
              <Link
                className="footer__link"
                to="/privacy"
              >
                <FormattedMessage id="footer.copyright.links.privacy_policy" />
              </Link>
            </div>
          </div>
        </div>
      </div>
      {showModal && (
        <Modal
          small
          title="Projetos Cofinanciados"
          onClose={handleModalClose}
        >
          <div className="footer__modal-content">
            <Link
              className="footer__modal-link"
              to="/projects/p24101"
              onClick={handleModalClose}
            >
              CENTRO-02-0752-FEDER-024101
            </Link>
            <Link
              className="footer__modal-link"
              to="/projects/p69466"
              onClick={handleModalClose}
            >
              CENTRO-01-02B7-FEDER-069466
            </Link>
          </div>
        </Modal>
      )}

    <script
      dangerouslySetInnerHTML={{
        __html: `
        (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
            (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
            m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
        })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');

            ga('create', 'UA-86353545-1', 'auto');
            ga('send', 'pageview');

            `,
          }}
        />
    </footer>
  );
};

export default Footer;
